import { LOCALSTORAGE_AUTH_TOKENS_KEY } from "commons/constants/auth";
import jwtDecode from "jwt-decode";

type token = string;

type decodedToken = {
  exp: number;
  role: string;
  roles: string[];
};

export const getToken = (): token | null => {
  let localStoreToken = localStorage.getItem(LOCALSTORAGE_AUTH_TOKENS_KEY) ?? null;

  if (!localStoreToken) return null;

  return localStoreToken;
};

export const isTokenExpired = (accessToken: string) => {
  try {
    const decodedToken = jwtDecode<decodedToken>(accessToken);
    return decodedToken.exp - 30 < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

export const saveToken = (token: token) => {
  localStorage.setItem(LOCALSTORAGE_AUTH_TOKENS_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(LOCALSTORAGE_AUTH_TOKENS_KEY);
};

export const getRoleByToken = () => {
  try {
    const token = getToken();
    if (!token) {
      return "";
    }
    const decodedToken = jwtDecode<decodedToken>(token);
    return decodedToken.role;
  } catch (error) {
    return "";
  }
};

export const getRolesByToken = () => {
  try {
    const token = getToken();
    if (!token) {
      return [];
    }

    const decodedToken = jwtDecode<decodedToken>(token);
    return decodedToken.roles;
  } catch (error) {
    return [];
  }
};

export const getRoles = () => {
  try {
    const roles = getRolesByToken();
    return Array.from(Object.keys(roles), (k: any) => roles[k]);
  } catch (error) {
    return [];
  }
};
