import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getToken } from 'commons/auth/tokens';
import { API_URL } from 'commons/constants/env';
import { ROUTES_CORE } from 'commons/constants/paths';
import { useNavigate } from 'react-router-dom';

const applyAppTokenInterceptor = (axiosClient: AxiosInstance, navigate: any) => {
    axiosClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        const accessToken = getToken();
        config.headers.Authorization = `Bearer ${accessToken}`;

        return Promise.resolve(config);
    });

  axiosClient.interceptors.response.use(
      async response => response,
    error => {
      switch (error.response?.status) {
        case 401:
        case 403:
          navigate(ROUTES_CORE.LOGOUT);
          break;
        case 404:
          navigate('/404');
          break;
      }

      return Promise.reject(error);
    }
  );
};

const axiosSecureInstance = axios.create({
    baseURL: API_URL,
});
axiosSecureInstance.defaults.headers.Accept = 'application/json';

applyAppTokenInterceptor(axiosSecureInstance, useNavigate);

export default axiosSecureInstance;
